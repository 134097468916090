/* Font Vairable */
$font-family: "Montserrat", sans-serif;
$font-extra-small: 14px;
$font-size-small: 16px;
$font-size-medium: 20px;
$font-size-large: 24px;
$font-normal: 500;
$font-bold: 700;
$font-style: normal;
$line-height-text: 140%;
$line-height-heading: 160%;