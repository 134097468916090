@import "../Abstract/variables.scss";

.patient-layout {
    // height: calc(100vh - 70px);

    .header-layout {
        .header-container {
            background-color: #f2f2f2;
            padding: 5px;

            .header-title {
                @include heading($black-color, $font-size-small, 0);

                i {
                    font-size: 18px;
                    color: #000;
                    font-weight: 500;
                }

                .user-icon {
                    display: block;
                }

                .back-icon {
                    display: none;
                }
            }

            .right-menu {
                .desktop-menu-wrap {
                    column-gap: 15px;

                    .search-container {
                        input[type="search"] {
                            @include subContent($black-color, $font-size-small - 2, 8px);
                            border: 1px solid #c8c8c8;
                            border-radius: 25px !important;
                        }
                    }

                    button {
                        border: none;
                        background-color: transparent;
                        display: flex;
                        align-items: center;
                    }

                    .refresh-btn,
                    .more-btn {
                        background-color: #0070E0;
                        padding: 5px 8px;
                        color: #fff;
                    }

                    .dropdown-menu {
                        .search-title {
                            @include heading($white-color, $font-size-small, 8px);
                            background-color: $main-color;
                        }

                        .search_Footer {
                            .buttons {
                                margin: 0px !important;
                                padding: 10px;
                                display: flex;
                                align-items: center;
                                column-gap: 10px;

                                &:nth-child(1) {
                                    background-color: #A3EBB1;
                                }

                                &:nth-child(2) {
                                    background-color: #f9ecef;
                                }
                            }
                        }
                    }
                }

                .mobile-menu-wrap {
                    .menu-items {
                        column-gap: 15px;

                        button {
                            border: none;
                            background-color: transparent;
                            display: flex;
                            align-items: center;
                        }

                        .refresh-btn,
                        .search-btn,
                        .filter-btn {
                            background-color: #0070E0;
                            padding: 5px 8px;
                            color: $white-color;
                        }

                        .dropdown-menu {
                            .search-title {
                                @include heading($white-color, $font-size-small, 8px);
                                background-color: $main-color;
                            }

                            .search_Footer {
                                .buttons {
                                    margin: 0px !important;
                                    padding: 10px;
                                    display: flex;
                                    align-items: center;
                                    column-gap: 15px;

                                    &:nth-child(1) {
                                        background-color: #A3EBB1;
                                    }

                                    &:nth-child(2) {
                                        background-color: #f9ecef;
                                    }
                                }
                            }
                        }
                    }

                }
            }
        }

        .mobile-search-container {
            display: none;

            span {
                i {
                    font-weight: 800;
                    font-size: 18px;
                    color: $black-color;
                }
            }

            input[type="search"] {
                @include subContent($black-color, $font-size-small - 2, 8px);
                border: 1px solid #c8c8c8;
                border-radius: 25px !important;
            }
        }
    }

    .patient-entry-layout {
        .patient-entry-form {
            form {
                .form-row {
                    .form-group {
                        label {
                            @include heading($black-color, $font-extra-small, 0);
                        }

                        input {
                            @include subContent($black-color, $font-extra-small, 8px);
                        }

                        .customer-search {
                            span.search-icon {
                                position: absolute;
                                display: inline-block;
                                top: 8px;
                                right: 5px;

                                i {
                                    font-size: 18px;
                                    font-weight: 700;
                                    color: $black-color;
                                }
                            }
                        }

                        .form-inner-group {
                            select {
                                @include heading($black-color, $font-extra-small, 8px);

                                option {
                                    @include subContent($black-color, $font-extra-small, 10px);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}


/* Mobile Responsive */
@media(width<=568px) {
    .patient-layout {
        .header-layout {
            .header-container {
                position: fixed;
                top: 0;
                left: 0;
                z-index: 1000;
                width: 100%;
                padding: 17px 12px !important;
                background-color: $white-color !important;

                .header-title {
                    font-size: $font-extra-small !important;
                }

                .user-icon {
                    display: none !important;
                }

                .back-icon {
                    display: block !important;
                }
            }

            .mobile-search-container {
                display: block !important;
                position: fixed;
                top: 0;
                left: 0;
                z-index: 10001;
                width: 100%;
                padding: 8.5px !important;
                background-color: #f2f2f2;
            }
        }

        .patient-entry-layout {
            padding-inline: 12px !important;
        }
    }
}