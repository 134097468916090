@import "../Abstract/variables.scss";

.testEntryDetailsLayout {
    .test-entry-header {
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

        .text-title {
            @include heading(transparent, $font-extra-small + 2, 0);
            background-image: linear-gradient(to right, #e1000a, #db2777, #2563eb);
            -webkit-background-clip: text;

            .back-icon {
                display: none;

                i {
                    font-weight: 700;
                    font-size: 18px;
                }
            }
        }

        .right-menu {
            .nav {
                column-gap: 10px;

                .nav-item {
                    .nav-link {
                        padding: 0;

                        input[type="search"],
                        input[type="date"] {
                            @include heading($black-color, $font-extra-small - 2, 5px 10px);
                            border: 1px solid #f4f4f4;
                            border-radius: 25px !important;
                        }
                    }

                    .rotate-icon {
                        background-color: #ebecf0;
                        padding: 10px;
                        display: flex;
                        align-items: center;
                        border-radius: 25px;

                        i {
                            font-size: 18px;
                            color: #000;
                            font-weight: 600;
                        }
                    }
                }

                .date-icon {
                    display: none;
                }
            }
        }
    }

    .test-entry-container {
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

        .tab-menu {
            p-tabmenu {
                .p-tabmenu-nav-container {
                    border-bottom: 1px solid #f4f4f4;

                    .p-tabmenu-nav-content {
                        .p-tabmenu-nav {
                            li {
                                a {
                                    @include heading($black-color, $font-extra-small, 10px);
                                    background-color: unset !important;
                                    background: unset !important;
                                    border: none !important;
                                    border-radius: unset !important;
                                    font-weight: 500;

                                    &:focus {
                                        box-shadow: none !important;
                                    }
                                }

                                &.p-highlight {
                                    a {
                                        color: #e1000a;
                                        border-bottom: 3px solid #e1000a !important;
                                        font-weight: 800 !important;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            /* Global Nav Tabs */
            .menuTabsLayout {
                overflow: auto;

                .tabItemsContainer {
                    ul.nav-pills {
                        border-bottom: 1px solid #e9ebec;
                        // overflow-y: hidden;
                        // overflow-x: auto;
                        flex-wrap: nowrap;

                        &::-webkit-scrollbar {
                            height: 5px;
                        }

                        &::-webkit-scrollbar-track {
                            background: transparent;
                        }

                        &::-webkit-scrollbar-thumb {
                            background: #dadada;
                            border-radius: 10px;
                        }

                        li.nav-item {
                            min-width: 200px;

                            a.nav-link {
                                @include heading($black-color, $font-extra-small, 0 0 12px);
                                font-weight: 600;
                                border-radius: unset;
                                transition: none;
                                justify-content: center;
                                width: -webkit-fill-available;
                                cursor: pointer;
                                color: $black-color;

                                &:hover {
                                    background: none;
                                    // border-bottom: 2px solid $main-color;
                                    // color: $main-color;
                                    transition: color .15s ease-in-out;
                                    margin-bottom: 0px;
                                }

                                &.active {
                                    background: none;
                                    border-bottom: 2px solid $main-color;
                                    color: $main-color;
                                    transition: color .15s ease-in-out;
                                    margin-bottom: 0px;
                                    font-weight: 700 !important;
                                }

                                span i {
                                    font-size: 15px;
                                    padding-right: 5px;
                                }
                            }
                        }
                    }
                }
            }
        }

        .test-entry-table {
            .table-responsive {
                max-height: calc(100vh - 100px);
                overflow: auto;

                table {
                    thead {
                        tr {
                            th {
                                @include heading($black-color, $font-extra-small, 10px);
                                background-color: #ebecf0;
                                position: sticky;
                                top: 0;
                                white-space: nowrap;
                            }
                        }
                    }

                    tbody {
                        tr {
                            &:nth-child(even) {
                                td {
                                    background-color: #f4f4f4;
                                }
                            }

                            td {
                                @include subContent($black-color, $font-extra-small, 10px);
                                vertical-align: middle;
                                white-space: nowrap;

                                button {
                                    @include subContent($white-color, $font-extra-small, 5px 1rem);
                                    font-weight: 600;
                                }

                                .todo-item-action {
                                    display: inline-flex;
                                    column-gap: 10px;

                                    a {
                                        text-decoration: none !important;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

/* Media Devices */
@media(width<=568px) {
    .testEntryDetailsLayout {
        .test-entry-header {
            position: fixed;
            top: 0;
            left: 0;
            z-index: 1000;
            width: 100%;
            padding: 12px !important;

            .text-title {
                .back-icon {
                    display: block !important;
                }
            }
        }

        .mobileview-search {
            position: fixed;
            top: 0;
            left: 0;
            z-index: 1001;

            .search-group,
            .date-group {
                padding: 12px !important;

                span {
                    i {
                        color: #000;
                        font-size: 18px;
                        font-weight: 800;
                    }
                }

                input[type="search"] {
                    @include heading($black-color, $font-extra-small - 2, 5px 10px);
                    border: 1px solid #f4f4f4;
                    border-radius: 25px !important;
                }
            }
        }
    }
}

@media(width<=430px) {
    .right-menu {
        .nav {
            .datepicker {
                display: none;
            }

            .date-icon {
                display: block !important;
            }
        }
    }
}