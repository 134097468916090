@import "./font.scss";
@import "./color.scss";

@mixin heading($color, $font-size, $padding) {
    color: $color;
    font: $font-style $font-bold #{$font-size}/#{$line-height-text} $font-family;
    letter-spacing: 0.03em;
    padding: $padding;
}

@mixin subContent($color, $font-size, $padding) {
    color: $color;
    font: $font-style $font-normal #{$font-size}/#{$line-height-text} $font-family;
    letter-spacing: 0.02em;
    padding: $padding;
}